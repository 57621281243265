import React, { useEffect, useState, Fragment, useMemo, useCallback } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import { format, formatDistance } from 'date-fns';
import { Column } from 'react-table';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowPathIcon,
  CloudArrowUpIcon,
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  ArchiveBoxIcon,
  BarsArrowDownIcon,
} from '@heroicons/react/24/solid';
import {
  Disclosure,
  Menu,
  Transition,
  MenuItem,
  MenuItems,
  MenuButton,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'file-saver';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ArrowUpOnSquareStackIcon, CloudArrowDownIcon, PlusIcon, TrashIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { BlobServiceClient } from '@azure/storage-blob';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { ReactComponent as CheckCircleIcon } from '../../icons/CheckCircle.svg';
import { ReactComponent as Loader } from '../../icons/Loader.svg';
import { Job } from '../../models/Job';
import { ImportLogItem } from '../../models/ImportLogItem';
import { useApi } from '../../contexts/ApiContext';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { HierarchyType } from '../../models/HierarchyType';
import { convertDate, downloadHandler, removeTimezoneOffset } from '../../Helper';
import { ConfirmStatusChange } from '../../components/ConfirmStatusChange';
import { useAuth } from '../../contexts/AuthContext';
import DocumentSlide, { Document } from '../../components/DocumentSlide';
import { EntityType } from '../../models/EntityType';
import { Roles } from '../../models/Role';
import ExportFrameworkModal from '../../components/ExportFrameworkModal';
import ExportAssetsModal from '../../components/ExportAssetsModal';
import { ImageQualityModal } from '../../ImageQualityModal';
import { BasicTable, EnhancedColumn } from '../../components/BasicTable';
import { numberFormat } from '../../Format';
import { useJob } from '../../contexts/JobContext';
import { DocumentType } from '../../models/DocumentType';
import { JobAssetSummary } from '../../models/JobAssetSummary';
import AssetInspectionChartModal from '../../components/AssetInspectionChartModal';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';

interface ReportCard {
  jobId?: number;
  jobName: string;
  status?: string;
  client: string;
  name: string;
  url: string;
  jobNumber?: number;
  projectedYearsSetup?: boolean;
  assetClasses?: HierarchyType[];
  defaultValue?: string;
  extension?: string;
  previousJobs?: HierarchyType[];
  levelOfService?: number;
  errorHandler?(): void;
}

const BLOB_URL = `${import.meta.env.VITE_BLOB_STORAGE_URL}${import.meta.env.VITE_SAS_KEY}`;

const CardView: React.FC<ReportCard> = ({
  jobId,
  status,
  name,
  client,
  jobName,
  assetClasses,
  defaultValue,
  url,
  extension,
  previousJobs,
  projectedYearsSetup,
  levelOfService,
  jobNumber,
  errorHandler,
}) => {
  const api = useApi();
  const [selectedAssetClass, setselectedAssetClass] = useState(
    assetClasses !== undefined && assetClasses.length > 0 && defaultValue === undefined ? assetClasses[0].label : 'All'
  );
  const [isDownloading, setIsDownloading] = useState(false);
  const defaultJob = previousJobs !== undefined && previousJobs.length > 0 ? previousJobs[0].value : 0;
  const [selectedProjectedYears, setSelectedProjectedYears] = useState<number>(10);
  const [previousJobId, setPreviousJobId] = useState(defaultJob);
  const [levelOfServiceLeft, setLevelOfServiceLeft] = useState(50);
  const { addToast } = useToast();
  const methodologyReportBulkImport = name === 'Asset Class Methodology' && selectedAssetClass === 'All';
  const assetClass = selectedAssetClass !== 'All' ? selectedAssetClass : '';
  const fileExt = extension ?? 'docx';
  const current = new Date();
  const date = `${current.getDate()}.${current.getMonth() + 1}.${current.getFullYear()}`;
  const downloadReport = (): void => {
    addToast(`Generating ${name} Report.`);
    setIsDownloading(true);
    api
      .post(
        url,
        {
          JobId: jobId,
          AssetClass: selectedAssetClass,
          PreviousJobId: previousJobId,
          ProjectedYears: selectedProjectedYears,
          LevelOfService: levelOfServiceLeft / 100,
        },
        { responseType: 'blob' }
      )
      .then(
        ({ data }) => {
          downloadHandler(
            data,
            `${jobNumber !== undefined ? `${jobNumber} ` : ''}${client}${
              methodologyReportBulkImport ? ' ' : ` ${assetClass} `
            }${status === 'Finalised' ? 'Finalised' : 'Draft'} ${name} ${date}.${
              methodologyReportBulkImport ? 'zip' : fileExt
            }`.replaceAll('  ', ' ')
          );
          addToast(`Downloaded ${name} Report.`);
        },
        ({ error }) => {
          console.error('error', error);
          return errorHandler ? errorHandler() : addToast(`Unable to generate ${name}`, ToastType.Error);
        }
      )
      .finally(() => {
        setIsDownloading(false);
      })
      .catch((error) => {
        addToast(`Unable to download report ${name} due to an error:`, ToastType.Error);
      });
  };

  return (
    <div className="relative space-y-2 bg-white px-4 py-4 sm:pt-6 sm:px-6 shadow rounded-lg flex flex-col align-center overflow-hidden">
      <div className="">
        <dt>
          {previousJobs !== undefined ? (
            <p
              className={`py-3 static text-sm font-medium truncate ${
                previousJobs?.length === 0 ? 'text-gray-300' : 'text-gray-500'
              } `}
            >
              {name}
            </p>
          ) : (
            <p className="py-3 static text-sm font-medium truncate text-gray-500">{name}</p>
          )}
        </dt>
        <dt>
          {assetClasses !== undefined && (
            <select
              name="assetClass"
              className="w-full my-2 block pl-3 pr-10 text-base border-none rounded-md bg-gray-100 font-medium  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e): void => setselectedAssetClass(e.target.value)}
            >
              {defaultValue !== undefined && <option value="All">All</option>}
              {assetClasses.map((ac) => (
                <option key={ac.value} value={ac.label}>
                  {ac.label}
                </option>
              ))}
            </select>
          )}
          {previousJobs !== undefined && (
            <select
              name="previousJob"
              className={`w-full my-2 block pl-3 pr-10 text-base border-none rounded-md bg-gray-100 font-medium ${
                previousJobs?.length === 0 ? 'disabled:opacity-40' : ''
              } focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
              disabled={previousJobs?.length === 0}
              onChange={(e): void => {
                setPreviousJobId(Number(e.target.value));
              }}
            >
              {previousJobs.map((ac) => (
                <option key={ac.value} value={ac.value}>
                  {ac.label}
                </option>
              ))}
            </select>
          )}
          {projectedYearsSetup && (
            <select
              name="projectedYears"
              className="w-full my-2 block pl-3 pr-10 text-base border-none rounded-md bg-gray-100 font-medium  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e): void => setSelectedProjectedYears(Number(e.target.value))}
            >
              <option value={10}>10 yrs</option>
              <option value={30}>30 yrs</option>
              <option value={50}>50 yrs</option>
            </select>
          )}
          {levelOfService !== undefined && (
            <div className="flex relative">
              <input
                type="number"
                name="levelOfService"
                onChange={(e): void => setLevelOfServiceLeft(Number(e.target.value))}
                className="w-full my-2 block pl-3 pr-10 text-base border-none rounded-md bg-gray-100 font-medium  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                defaultValue={50}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">%</div>
            </div>
          )}
          {/* {assetClasses === undefined && previousJobs === undefined && !projectingYearsSetup && (
            <select
              className="w-full my-2 block pl-3 pr-10 text-base border-none rounded-md bg-gray-100 font-medium  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              aria-label="Empty select"
            />
          )} */}
        </dt>
      </div>
      <dt>
        {url === undefined ? (
          <a href="#" className="inline-block py-3 font-medium text-indigo-600 hover:text-indigo-500">
            View<span className="sr-only"> {name}</span>
          </a>
        ) : (
          <button
            className={`btn text-indigo-700 bg-white hover:bg-gray-50 ${
              previousJobs?.length === 0 ? 'disabled:opacity-40' : ''
            } focus:outline-none`}
            onClick={downloadReport}
            disabled={previousJobs?.length === 0 || isDownloading}
            value="View"
          >
            {isDownloading ? (
              <>
                <span>Downloading</span>
                <Loader className="animate-spin w-5 h-5 mx-2" />
              </>
            ) : (
              <span>Download</span>
            )}
          </button>
        )}
      </dt>
    </div>
  );
};

const JobView: React.FC<{}> = () => {
  const api = useApi();
  const {
    isValuationReportsOpen,
    setIsValuationReportsOpen,
    isGenerateReportsOpen,
    setIsGenerateReportsOpen,
    isAssetManagementToolsOpen,
    setIsAssetManagementToolsOpen,
    isImportHistoryOpen,
    setIsImportHistoryOpen,
    currentPage,
    setCurrentPage,
    tablePageSize,
    setTablePageSize,
  } = useJob();
  const { id = '' } = useParams<{ id: string }>();
  const [isDocumentSlideOpen, setIsDocumentSlideOpen] = useState<boolean>(false);
  const [isOpen, setImportFrameworkModalOpen] = useState<boolean>(false);
  const [isExportImageQualityOpen, setIsExportImageQualityOpen] = useState<boolean>(false);
  const [valuationReports, setValuationReports] = useState<ReportCard[]>([]);
  const [assetManagementReports, setAssetManagementReports] = useState<ReportCard[]>([]);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isRevalidating, setIsRevalidating] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isAssetsExporting, setIsAssetsExporting] = useState<boolean>(false);
  const [isArchiving, setIsArchiving] = useState<boolean>(false);
  const { addToast } = useToast();
  const { roles, client, clientGuid } = useAuth();
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [blobToDelete, setBlobToDelete] = useState<string>('');
  const [isDeletingDocuments, setIsDeletingDocuments] = useState<boolean>(false);
  const [isExportingDocuments, setIsExportingDocuments] = useState<boolean>(false);
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] = useState<boolean>(false);
  const blobServiceClient: BlobServiceClient = new BlobServiceClient(BLOB_URL);
  const [documents, setDocuments] = useState<Document[]>([] as Document[]);
  const [isAssetInspectionStatusChartOpen, setIsAssetInspectionStatusChartOpen] = useState<boolean>(false);
  const {
    isLoading: isLoadingJob,
    error: jobError,
    data: job = {} as Job,
  } = useQuery<Job, Error>(['job', id], () => api.get(`/api/Job/Get?jobId=${id}`).then((res) => res.data));

  const { data: previousJobList = [] as HierarchyType[] } = useQuery<HierarchyType[], Error>(
    ['previousJobList', id],
    () => api.get(`/api/Job/ListOptions?jobStatus=Archive`).then(({ data }) => data),
    { enabled: Object.keys(job).length > 0 }
  );
  const { data: assetClassList = [] as HierarchyType[] } = useQuery<HierarchyType[], Error>(
    ['assetClassListByJob', id],
    () => api.get(`/api/AssetClass/ListByJob?jobId=${id}`).then(({ data }) => data),
    { enabled: Object.keys(job).length > 0 }
  );

  const { isLoading: isLoadingAssetSummary, data: jobAssetSummary = {} as JobAssetSummary } = useQuery<
    JobAssetSummary,
    Error
  >(['assetCount', id], () => api.get(`/api/Job/AssetCount?jobId=${id}`).then(({ data }) => data), {
    enabled: Object.keys(job).length > 0,
  });

  const { data: jobImportLogList = [] as ImportLogItem[] } = useQuery<ImportLogItem[], Error>(
    ['jobImportLogList', id],
    () => api.get(`/api/ImportLog/ListJobLogs?jobId=${id}`).then(({ data }) => data),
    { enabled: Object.keys(job).length > 0 }
  );

  async function fetchDocs(): Promise<Document[]> {
    const query = `@container='${clientGuid}' AND jobId = '${id}' AND type = 'Document' AND documentType = 'Report' AND uploaded > '01-01-1900 00:00:00Z'`;
    const blobResults = blobServiceClient.findBlobsByTags(query);
    const tempDocs: Array<Document> = [];
    try {
      for await (const blob of blobResults) {
        const blobName = blob.name.split('/');
        tempDocs.push({
          id: blob.name,
          url: `${import.meta.env.VITE_BLOB_STORAGE_URL}/${clientGuid}/${blob.name}`,
          name: `${blobName[1]}`,
          type: blob.tags?.documentType as DocumentType,
          uploadDate: convertDate(blob.tags?.uploaded),
        });
      }
    } catch (error) {
      // addToast('Could not load images', ToastType.Error);
      console.error('error', error);
    }
    setDocuments(tempDocs);
    return tempDocs;
  }

  const deleteAction = (blobName: string): void => {
    setIsDeleteOpen(true);
    setBlobToDelete(blobName);
  };

  const deleteDocument = async (): Promise<void> => {
    const container = blobServiceClient.getContainerClient(clientGuid);
    const deleteString = `${id}/${blobToDelete}`;
    const result = container
      .deleteBlob(deleteString)
      .then(() => {
        addToast(`Deleted ${blobToDelete}`);
        setTimeout(() => {
          fetchDocs()
            .then((data) => console.log('got docs', data))
            .catch((error) => console.error('not got docs', error));
        }, 2050);
      })
      .catch((error) => addToast(`Unable to delete ${blobToDelete}`, ToastType.Error));
  };

  const deleteDocuments = async (blobsToDelete: string[]): Promise<void> => {
    const container = blobServiceClient.getContainerClient(clientGuid);
    let deletedDocuments = 0;
    setIsDeletingDocuments(true);
    for (let i = 0; i < blobsToDelete.length; i++) {
      deletedDocuments++;
      await container
        .deleteBlob(blobsToDelete[i])
        .then(() => {})
        .catch((error) => addToast(`Unable to delete ${blobsToDelete[i]}`, ToastType.Error));
    }
    setDocuments(documents.filter(({ id: blobName }) => !blobsToDelete.includes(blobName)));
    setIsDeletingDocuments(false);
    addToast(`Deleted ${deletedDocuments} documents.`);
  };

  const exportDocuments = (blobsToExport: string[]): void => {
    const zip = new JSZip();
    let count = 0;
    const zipFileName = `${job.name} Valuation Reports.zip`;
    setIsExportingDocuments(true);
    blobsToExport.forEach((url, i) => {
      const fileName = blobsToExport[i].split('/')[1];
      JSZipUtils.getBinaryContent(
        `${import.meta.env.VITE_BLOB_STORAGE_URL}/${clientGuid}/${url}`,
        async (
          err,
          data:
            | string
            | ArrayBuffer
            | number[]
            | Uint8Array
            | Blob
            | Promise<string | ArrayBuffer | number[] | Uint8Array | Blob>
        ) => {
          if (err) {
            console.log('err', err);
            setIsExportingDocuments(false);
          }
          try {
            zip.file(fileName, data, { binary: true });
            count++;
            if (count == blobsToExport.length) {
              zip.generateAsync({ type: 'blob', streamFiles: true }).then((content) => {
                saveAs(content, zipFileName);
                setIsExportingDocuments(false);
                addToast(`Downloading ${count} documents as zip.`);
              });
            }
          } catch (e) {
            setIsExportingDocuments(false);
          }
        }
      );
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    try {
      for (let i = 0; i < acceptedFiles.length; i += 1) {
        const formData = new FormData();
        formData.append('file', acceptedFiles[i]);
        formData.append('documentType', 'Report');
        formData.append('entity', 'Job');
        formData.append('entityId', id.toString());
        api.post('/api/Document/Import', formData, {}).then(
          ({ data }) => {
            setTimeout(() => {
              fetchDocs();
            }, 2050);
          },
          (error) => {
            if (axios.isCancel(error)) {
              addToast('Request Cancelled', ToastType.Warn);
            } else {
              addToast('API Request Failed', ToastType.Error);
            }
          }
        );
      }
      addToast(`${acceptedFiles.length} documents have been uploaded.`);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  useEffect(() => {
    async function fetching(): Promise<void> {
      await fetchDocs();
    }
    fetching();
  }, []);

  const columns: EnhancedColumn<ImportLogItem>[] = React.useMemo(
    () => [
      {
        Header: 'File Name',
        headerClassName: 'px-3.5 py-3',
        accessor: 'fileName',
        className: 'px-3.5 py-3 whitespace-nowrap text-sm leading-5',
      },
      {
        Header: 'Result',
        headerClassName: 'px-3.5 py-3',
        accessor: 'isSuccess',
        className: 'px-3.5 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ value }): JSX.Element => {
          if (value) {
            return (
              <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-600/20">
                Success
              </span>
            );
          }
          return (
            <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
              Error
            </span>
          );
        },
      },
      {
        Header: 'User',
        headerClassName: 'px-3.5 py-3',
        accessor: 'createdBy',
        className: 'px-3.5 py-3 whitespace-nowrap text-sm font-medium leading-5',
      },

      {
        Header: 'Created',
        headerClassName: 'px-3.5 py-3',
        accessor: 'created',
        className: 'px-3.5 py-3 whitespace-nowrap text-sm leading-5',
        // Cell: ({ value }): string => (
        //   format(new Date(value), "dd/mm/yyyy hh:mm aaaaa'm'")
        // ),
        Cell: ({ value }): string => formatDistance(removeTimezoneOffset(new Date(value)), new Date(), { addSuffix: true }),
      },
      {
        Header: '',
        headerClassName: 'px-3.5 py-3',
        accessor: 'id',
        className: 'px-3.5 py-3 text-sm leading-5 font-medium sm:truncate',
        Cell: ({ value }): JSX.Element => (
          <Link to={`/importjoblog/${value}`} className="text-indigo-600 hover:text-indigo-900 font-medium">
            View
          </Link>
        ),
      },
    ],
    []
  );

  const documentColumns: Column<Document>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        className: 'px-2 py-2 whitespace-normal text-sm leading-5 text-gray-500',
      },
      {
        Header: 'Uploaded',
        accessor: 'uploadDate',
        className: 'px-2 py-2 whitespace-nowrap text-sm leading-5 text-gray-500',
        sortType: 'datetime',
        Cell: ({ row, value }): string => format(value, 'dd MMM yyyy'),
      },
      {
        Header: !roles.includes(Roles.Viewer) && (
          <div {...getRootProps()} className="flex items-center justify-center">
            <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only" />
            <button className="whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-900" type="button">
              <PlusIcon className="w-5 h-5" />
            </button>
          </div>
        ),
        accessor: 'url',
        className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        Cell: ({ row, value }): React.ReactElement => (
          <div className="space-x-6 flex justify-center">
            <a href={value} target="_blank" rel="noreferrer" className="btn-link">
              <CloudArrowDownIcon className="h-4 w-4" />
            </a>
            {!roles.includes(Roles.Viewer) && (
              <button
                type="button"
                onClick={(): void => {
                  deleteAction(row.original.name);
                }}
                className="btn-link"
              >
                <TrashIcon className="h-4 w-4 hover:text-red-700" />
              </button>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const { name, status, type, effectiveDateOfValuation, assetClasses = [], externalId } = job;
  useEffect(() => {
    if (assetClassList.length > 0) {
      setValuationReports([
        {
          jobId: parseInt(id),
          jobName: job.name,
          client,
          status,
          jobNumber: externalId ? parseInt(externalId) : undefined,
          name: 'Summary Report',
          assetClasses: assetClassList,
          url: '/api/Reports/GetSummaryReport',
          defaultValue: 'All',
          errorHandler: (): void => {
            addToast(
              'Unable to generate Summary report. This maybe due to dataset being too large or the job is missing report data, please try refreshing the report data in the actions dropdown.',
              ToastType.Error
            );
          },
        },
        {
          jobId: parseInt(id),
          jobName: job.name,
          client,
          status,
          jobNumber: externalId ? parseInt(externalId) : undefined,
          name: 'Methodology Sub Reports',
          defaultValue: 'All',
          assetClasses: [...assetClassList],
          url: '/api/Reports/GetMethodologySubReports',
          extension: 'xlsx',
        },
        {
          jobId: parseInt(id),
          jobName: job.name,
          client,
          status,
          jobNumber: externalId ? parseInt(externalId) : undefined,
          name: 'Asset Class Methodology',
          defaultValue: 'All',
          assetClasses: [...assetClassList],
          url: '/api/Reports/GetMethodologyReport',
        },
        {
          jobId: parseInt(id),
          jobName: job.name,
          client,
          status,
          jobNumber: externalId ? parseInt(externalId) : undefined,
          name: 'Valuation Report Tables',
          assetClasses: assetClassList,
          defaultValue: 'All',
          url: '/api/Reports/GetGeneralValuationReports',
          extension: 'xlsx',
        },
        {
          jobId: parseInt(id),
          jobName: job.name,
          client,
          status,
          jobNumber: externalId ? parseInt(externalId) : undefined,
          name: 'Changes In Values',
          url: '/api/Reports/GetChangesReport',
          extension: 'xlsx',
          previousJobs: previousJobList,
          errorHandler: (): void => {
            addToast(
              'Unable to generate Changes report. No existing report data exists for this previous Job due to not being archived in AVP.',
              ToastType.Error
            );
          },
        },
        {
          jobId: parseInt(id),
          jobName: job.name,
          client,
          status,
          jobNumber: externalId ? parseInt(externalId) : undefined,
          name: 'Depreciation Analysis',
          url: '/api/Reports/GetDepreciationAnalysisReport',
          extension: 'xlsx',
        },
        // {
        //   jobId: id,
        //   name: 'Movements Reconciliation',
        //   url: '/api/Reports/GetMovementsReconciliationReport',
        //   extension: 'xlsx',
        //   previousJobs: previousJobList,
        // },
      ]);
      setAssetManagementReports([
        {
          jobId: parseInt(id),
          jobName: job.name,
          client,
          status,
          jobNumber: externalId ? parseInt(externalId) : undefined,
          name: 'Distribution By Score',
          url: '/api/Reports/GetDistributionByScoreReport',
          extension: 'xlsx',
        },
        {
          jobId: parseInt(id),
          jobName: job.name,
          client,
          status,
          jobNumber: externalId ? parseInt(externalId) : undefined,
          name: 'Distribution By Value',
          url: '/api/Reports/GetDistributionByValueReport',
          extension: 'xlsx',
        },
        {
          jobId: parseInt(id),
          jobName: job.name,
          client,
          status,
          jobNumber: externalId ? parseInt(externalId) : undefined,
          name: 'Projected Renewal Costs',
          assetClasses: [...assetClassList],
          defaultValue: 'All',
          url: '/api/Reports/GetRenewalCostReport',
          extension: 'xlsx',
          projectedYearsSetup: true,
          errorHandler: (): void => {
            addToast(
              'Unable to generate Renewal report. This maybe due to the job missing report data, please try refreshing the report data in the actions dropdown.',
              ToastType.Error
            );
          },
        },
        ...(job.isMaintenance
          ? [
              {
                jobId: parseInt(id),
                jobName: job.name,
                client,
                status,
                jobNumber: externalId ? parseInt(externalId) : undefined,
                name: 'Maintenance Plan Report',
                assetClasses: [...assetClassList],
                defaultValue: 'All',
                url: '/api/Reports/GetMaintenancePlanReport',
                extension: 'xlsx',
                errorHandler: (): void => {
                  addToast(
                    'Unable to generate Maintenance Plan Report. This maybe due to the job missing report data, please try refreshing the report data in the actions dropdown.',
                    ToastType.Error
                  );
                },
              },
            ]
          : []),
        {
          jobId: parseInt(id),
          jobName: job.name,
          client,
          status,
          jobNumber: externalId ? parseInt(externalId) : undefined,
          name: 'Projected Cost to Bring to Satisfactory',
          url: '/api/Reports/GetCostToBringToSatisfactoryReport',
          extension: 'xlsx',
          levelOfService: 0,
        },
        {
          jobId: parseInt(id),
          jobName: job.name,
          client,
          status,
          jobNumber: externalId ? parseInt(externalId) : undefined,
          name: 'Modified Component Assumptions',
          url: '/api/Reports/ModifiedAssumptionsReport',
          extension: 'xlsx',
        },
      ]);
    }
  }, [assetClassList, previousJobList, job]);

  const exportAllImages = (jobId: number, quality: string): void => {
    setIsExporting(true);
    api.post('/api/Job/ExportImages', { jobId, quality }, { responseType: 'blob' }).then(
      ({ data }) => {
        setIsExporting(false);
        downloadHandler(data, `Images for ${job.name}.zip`);
      },
      (error) => {
        setIsExporting(false);
        addToast(
          `Error too many images to export for Job: ${job.name}. Please contact support@assetvaluer.net for assistance.`,
          ToastType.Error
        );
      }
    );
  };

  const refreshData = (): void => {
    setIsRefreshing(true);
    api
      .get(`/api/Job/RefreshReportData?jobId=${id}`)
      .then(({ data }) => {
        setIsRefreshing(false);
      })
      .catch((error) => {
        setIsRefreshing(false);
        const errorStatus = { ...error };
        if (errorStatus.response.status === 503) {
          addToast('A refresh is currently being executed.', ToastType.Error);
        } else {
          addToast(`Unable to Refresh Report Data for ${job.name}.`, ToastType.Error);
        }
      });
  };

  const revalidateAssets = (): void => {
    setIsRevalidating(true);
    addToast(`Beginning revalidation of Job: ${job.name}.`);
    api
      .get(`/api/Asset/Revalidate?jobId=${id}`)
      .then(({ data }) => {
        setIsRevalidating(false);
      })
      .catch((error) => {
        setIsRevalidating(false);
        const errorStatus = { ...error };
        if (errorStatus.response.status === 503) {
          addToast('A refresh is currently being executed.', ToastType.Error);
        } else {
          addToast(`Unable to Refresh Report Data for ${job.name}.`, ToastType.Error);
        }
      });
  };

  const queryClient = useQueryClient();

  const isArchive = status === 'Archive';

  const changeStatus = (): void => {
    setIsArchiving(true);
    api.post(`/api/Job/Archive?jobId=${id}`).then(
      () => {
        addToast(`Archived job ${job.name}`);
        setIsArchiving(false);
        queryClient.invalidateQueries(['job', parseInt(id)]);
        queryClient.invalidateQueries(['jobslist']);
      },
      (error) => {
        setIsArchiving(false);
        addToast(`Unable to Archive ${job.name}. Please see System Administrator`, ToastType.Error);
        queryClient.invalidateQueries(['job', parseInt(id)]);
        queryClient.invalidateQueries(['jobslist']);
      }
    );
  };

  const openChangeStatusModal = (): void => {
    setIsStatusChangeModalOpen(true);
  };

  if (isLoadingJob) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader className="animate-spin w-6 h-6 mb-3" />
        <p className="text-sm font-medium text-gray-500">Loading Job</p>
      </div>
    );
  }

  if (jobError) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <p className="text-sm font-medium text-red-500">Error Loading Job</p>
      </div>
    );
  }

  return (
    <>
      {isArchiving ? (
        <div className="flex flex-col justify-center items-center h-screen">
          <Loader className="animate-spin w-6 h-6 mb-3" />
          <p className="font-bold text-gray-500">Archiving Job....</p>
          <p className="w-1/3 text-center font-medium text-gray-500">
            This may take a few minutes, please don&apos;t close this page.
          </p>
        </div>
      ) : (
        <div className="py-4">
          <div className="px-4 sm:px-6 lg:px-8 space-y-4">
            {/* <DocumentSlide entity={EntityType.Job} entityId={id} open={open} setOpen={setOpen} /> */}
            <div className="rounded-lg bg-white shadow">
              <div className="lg:flex lg:items-center lg:justify-between p-6">
                <div className="flex-1 min-w-0 space-y-2">
                  <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl">{name}</h2>
                  <p className="text-sm font-bold leading-7 text-gray-500 md:text-base">{externalId}</p>
                  <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                    {assetClasses.map((a) => (
                      <span
                        key={a.value}
                        className="inline-flex items-center px-2 py-0.5 text-xs rounded-md rounded-md font-medium bg-indigo-100 text-indigo-800 m-2"
                      >
                        {a.label}
                      </span>
                    ))}
                  </div>
                </div>

                <button onClick={(): void => setIsDocumentSlideOpen(true)} className="btn btn-secondary">
                  <span>Documents</span>
                </button>

                {!isArchive && (
                  <div className="mt-5 flex lg:mt-0 lg:ml-4 z-10 space-x-3">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <MenuButton
                          disabled={isExporting || isArchiving || isRefreshing || isRevalidating}
                          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                        >
                          {isExporting || isArchiving || isRefreshing || isRevalidating ? (
                            <Loader className="animate-spin -ml-1 mr-2 h-5 w-5 text-gray-400" />
                          ) : (
                            <>
                              <span>Actions</span>
                              <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                            </>
                          )}
                        </MenuButton>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <MenuItems className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                          <div className="py-1">
                            <MenuItem>
                              {({ focus }): React.ReactElement => (
                                <button
                                  type="button"
                                  onClick={(): void => setIsAssetsExporting(true)}
                                  className={`group flex items-center px-4 py-2 text-sm w-full ${
                                    focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                  }`}
                                >
                                  <CloudArrowDownIcon
                                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                  />
                                  Export Assets
                                </button>
                              )}
                            </MenuItem>
                            {!roles.includes(Roles.Viewer) &&
                              job.status === 'Open' &&
                              assetClasses.find((a) => !a.isValid) === undefined && (
                                <MenuItem>
                                  {({ focus }): React.ReactElement => (
                                    <Link
                                      to={`/jobs/${id}/import`}
                                      type="button"
                                      className={`group flex items-center px-4 py-2 text-sm ${
                                        focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                      }`}
                                    >
                                      <CloudArrowUpIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                      Import Assets
                                    </Link>
                                  )}
                                </MenuItem>
                              )}
                            {!roles.includes(Roles.Viewer) &&
                              job.status === 'Open' &&
                              assetClasses.find((a) => !a.isValid) !== undefined && (
                                <MenuItem>
                                  <button
                                    disabled
                                    className="group flex items-center px-4 opacity-50 py-2 text-sm text-gray-700"
                                  >
                                    <CloudArrowUpIcon className="mr-3 h-5 w-5 text-gray-400 opacity-50" aria-hidden="true" />
                                    Import Assets
                                  </button>
                                </MenuItem>
                              )}
                          </div>
                          {!roles.includes(Roles.Viewer) && (
                            <div className="py-1">
                              <MenuItem>
                                {({ focus }): React.ReactElement => (
                                  <button
                                    onClick={(): void => setImportFrameworkModalOpen(true)}
                                    className={`group flex items-center px-4 py-2 text-sm w-full ${
                                      focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                    }`}
                                  >
                                    <BarsArrowDownIcon
                                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                    Export Framework
                                  </button>
                                )}
                              </MenuItem>
                            </div>
                          )}
                          {!roles.includes(Roles.Viewer) && (job.status === 'Open' || job.status === 'Draft') && (
                            <div className="py-1">
                              <MenuItem>
                                {({ focus }): React.ReactElement => (
                                  <button
                                    type="button"
                                    onClick={(): void => refreshData()}
                                    className={`group flex items-center px-4 py-2 text-sm w-full ${
                                      focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                    }`}
                                  >
                                    <ArrowPathIcon
                                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                    Refresh Report Data
                                  </button>
                                )}
                              </MenuItem>
                              <MenuItem>
                                {({ focus }): React.ReactElement => (
                                  <button
                                    type="button"
                                    onClick={(): void => revalidateAssets()}
                                    className={`group flex items-center px-4 py-2 text-sm w-full ${
                                      focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                    }`}
                                  >
                                    <CheckCircleIcon
                                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                    Revalidate Assets
                                  </button>
                                )}
                              </MenuItem>
                            </div>
                          )}
                          <div className="py-1">
                            <MenuItem>
                              {({ focus }): React.ReactElement => (
                                <button
                                  onClick={(): void => setIsExportImageQualityOpen(true)}
                                  type="button"
                                  className={`group flex items-center px-4 py-2 text-sm w-full ${
                                    focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                  }`}
                                >
                                  <ArrowDownTrayIcon
                                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                  />
                                  Download Images
                                </button>
                              )}
                            </MenuItem>
                            {!roles.includes(Roles.Viewer) && (job.status === 'Open' || job.status === 'Draft') && (
                              <MenuItem>
                                {({ focus }): React.ReactElement => (
                                  <Link
                                    to={`/jobs/${id}/importimages`}
                                    type="button"
                                    className={`group flex items-center px-4 py-2 text-sm ${
                                      focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                    }`}
                                  >
                                    <ArrowUpTrayIcon
                                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                    Upload Images
                                  </Link>
                                )}
                              </MenuItem>
                            )}
                          </div>
                          {(job.status === 'Open' || job.status === 'Draft') && (
                            <div className="py-1">
                              <MenuItem>
                                {({ focus }): React.ReactElement => (
                                  <Link
                                    to={`/jobs/${id}/importdocuments`}
                                    type="button"
                                    className={`group flex items-center px-4 py-2 text-sm ${
                                      focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                    }`}
                                  >
                                    <ArrowUpOnSquareStackIcon
                                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                    Upload Documents
                                  </Link>
                                )}
                              </MenuItem>
                            </div>
                          )}
                          {!roles.includes(Roles.Viewer) && job.status === 'Finalised' && (
                            <div className="py-1">
                              <MenuItem>
                                {({ focus }): React.ReactElement => (
                                  <button
                                    onClick={(): void => openChangeStatusModal()}
                                    type="button"
                                    className={`group flex items-center px-4 py-2 text-sm w-full ${
                                      focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                    }`}
                                  >
                                    <ArchiveBoxIcon
                                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                    Archive
                                  </button>
                                )}
                              </MenuItem>
                            </div>
                          )}
                        </MenuItems>
                      </Transition>
                    </Menu>

                    {!roles.includes(Roles.Viewer) && (
                      <Link to={`/jobs/${id}/edit`} className="btn btn-primary">
                        <span>Edit</span>
                      </Link>
                    )}

                    {/* Dropdown */}
                    <Menu as="span" className="relative sm:hidden">
                      <MenuButton className="btn border-gray-300 shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        More
                        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                      </MenuButton>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <MenuItems className="origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <MenuItem>
                            {({ focus }): JSX.Element => (
                              <NavLink
                                to="#"
                                className={`block px-4 py-2 text-sm text-gray-700 ${focus ? 'bg-gray-100' : ''}`}
                              >
                                Edit
                              </NavLink>
                            )}
                          </MenuItem>
                          <MenuItem>
                            {({ focus }): JSX.Element => (
                              <NavLink
                                to="#"
                                className={`block px-4 py-2 text-sm text-gray-700 ${focus ? 'bg-gray-100' : ''}`}
                              >
                                View
                              </NavLink>
                            )}
                          </MenuItem>
                        </MenuItems>
                      </Transition>
                    </Menu>
                  </div>
                )}
              </div>
              <div className="border-t border-gray-200 bg-gray-50 rounded-b-lg grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-4 sm:divide-y-0 sm:divide-x">
                <div className="px-6 py-5 text-sm font-medium text-center items-center flex justify-center">
                  {!isArchive ? (
                    <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      {status}
                    </span>
                  ) : (
                    <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                      {status}
                    </span>
                  )}
                </div>
                <div className="px-6 py-5 text-sm font-medium text-center">
                  <div className="px-3 pt-1.5">
                    <span className="text-gray-900">Valuation Date: </span>
                    <span className="text-gray-600">{format(new Date(effectiveDateOfValuation), 'dd/MM/yyy')}</span>
                  </div>
                </div>
                <div className="px-6 py-5 text-sm font-medium text-center">
                  <div className="px-3 pt-1.5">
                    <span className="text-gray-600">{type}</span>
                  </div>
                </div>
                <div className="px-6 py-5 font-medium text-center">
                  {!isLoadingAssetSummary ? (
                    <div className="px-3 pt-1.5">
                      <span className="text-gray-900 text-base">{numberFormat.format(jobAssetSummary.assetCount)}</span>
                      <button
                        disabled={jobAssetSummary.assetCount === 0}
                        onClick={(): void => setIsAssetInspectionStatusChartOpen(true)}
                        className="ml-1 whitespace-nowrap text-sm font-medium  disabled:text-gray-600 text-indigo-600  hover:text-indigo-900"
                      >
                        assets
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center">
                      <Loader className="animate-spin w-5 h-5 mx-2" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {assetClasses.find((a) => !a.isValid) !== undefined && (
              <div className="rounded-md bg-red-50 p-4 shadow">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      There are {assetClasses.filter((x) => !x.isValid).length} errors with your Job
                    </h3>
                    <div className="mt-2 text-sm text-red-700">
                      <ul className="list-disc space-y-1 pl-5">
                        {assetClasses.map(
                          (a) =>
                            !a.isValid && (
                              <li key={a.value}>
                                Asset Class <b>{a.label}</b> has no Valuation Profile Rules
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!isArchive && assetClasses.length == 0 && (
              <div className="rounded-md bg-red-50 p-4 shadow">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm text-red-800">
                      There are no <b>Asset Classes</b> associated with your Job.
                    </h3>
                    <div className="mt-2 text-sm text-red-700">
                      <ul className="list-disc space-y-1 pl-5">
                        <li>Please assign an asset class to your job by using the edit function.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Disclosure defaultOpen={isValuationReportsOpen}>
              {({ open }): JSX.Element => (
                <>
                  <DisclosureButton as="div">
                    <button
                      type="button"
                      className="flex space-x-6 text-sm font-medium text-left rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-opacity-75"
                      onClick={(): void => setIsValuationReportsOpen(!isValuationReportsOpen)}
                    >
                      <div className="text-lg leading-6 font-medium text-gray-900">Valuation Reports</div>

                      <ChevronUpIcon className={`${open ? 'transform rotate-180' : ''} w-5 h-5 ease-in-out duration-150`} />
                    </button>
                  </DisclosureButton>
                  <DisclosurePanel className="pb-2 text-sm text-gray-500">
                    {!roles.includes(Roles.Viewer) ? (
                      <div>
                        <BasicTable
                          data={documents}
                          columns={documentColumns}
                          tablePageSize={5}
                          deleteAction={deleteDocuments}
                          isDeleting={isDeletingDocuments}
                          exportAction={exportDocuments}
                          isExporting={isExportingDocuments}
                          showHead
                          context="Document"
                        />
                      </div>
                    ) : (
                      <BasicTable
                        data={documents}
                        columns={documentColumns}
                        tablePageSize={5}
                        exportAction={exportDocuments}
                        isExporting={isExportingDocuments}
                        showHead
                        context="Document"
                      />
                    )}
                  </DisclosurePanel>
                </>
              )}
            </Disclosure>

            {assetClassList.length > 0 && !isArchive && (
              <>
                <Disclosure defaultOpen={isGenerateReportsOpen}>
                  {({ open }): JSX.Element => (
                    <>
                      <DisclosureButton as="div">
                        <button
                          type="button"
                          className="flex space-x-6 text-sm font-medium text-left rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-opacity-75"
                          onClick={(): void => setIsGenerateReportsOpen(!isGenerateReportsOpen)}
                        >
                          <div className="text-lg leading-6 font-medium text-gray-900">Generate Reports</div>
                          <ChevronUpIcon
                            className={`${open ? 'transform rotate-180' : ''} w-5 h-5 ease-in-out duration-150`}
                          />
                        </button>
                      </DisclosureButton>
                      <DisclosurePanel className="pb-2 text-sm text-gray-500">
                        <dl className="mt-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                          {valuationReports.map((reportCard: ReportCard, index) => (
                            <CardView key={index} {...reportCard} />
                          ))}
                        </dl>
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
                <Disclosure defaultOpen={isAssetManagementToolsOpen}>
                  {({ open }): JSX.Element => (
                    <>
                      <DisclosureButton as="div">
                        <button
                          type="button"
                          className="flex space-x-6 text-sm font-medium text-left rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-opacity-75"
                          onClick={(): void => setIsAssetManagementToolsOpen(!isAssetManagementToolsOpen)}
                        >
                          <div className="text-lg leading-6 font-medium text-gray-900">Asset Management Tools</div>
                          <ChevronUpIcon
                            className={`${open ? 'transform rotate-180' : ''} w-5 h-5 ease-in-out duration-150`}
                          />
                        </button>
                      </DisclosureButton>
                      <DisclosurePanel className="pb-2 text-sm text-gray-500">
                        <dl className="mt-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                          {assetManagementReports.map((reportCard: ReportCard, index) => (
                            <CardView key={index} {...reportCard} />
                          ))}
                        </dl>
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>

                {roles.includes(Roles.Valuer) && (
                  <Disclosure defaultOpen={isImportHistoryOpen}>
                    {({ open }): JSX.Element => (
                      <>
                        <DisclosureButton as="div">
                          <button
                            type="button"
                            className="flex space-x-6 text-sm font-medium text-left rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-opacity-75"
                            onClick={(): void => setIsImportHistoryOpen(!isImportHistoryOpen)}
                          >
                            <div className="text-lg leading-6 font-medium text-gray-900">Import History</div>
                            <ChevronUpIcon
                              className={`${open ? 'transform rotate-180' : ''} w-5 h-5 ease-in-out duration-150`}
                            />
                          </button>
                        </DisclosureButton>
                        <DisclosurePanel className="pb-2 text-sm text-gray-500">
                          <BasicTable
                            data={jobImportLogList}
                            columns={columns}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            tablePageSize={tablePageSize}
                            setTablePageSize={setTablePageSize}
                          />
                        </DisclosurePanel>
                      </>
                    )}
                  </Disclosure>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <AssetInspectionChartModal
        isOpen={isAssetInspectionStatusChartOpen}
        closeModal={setIsAssetInspectionStatusChartOpen}
        jobAssetSummary={jobAssetSummary}
      />
      <ExportAssetsModal
        isOpen={isAssetsExporting}
        closeModal={setIsAssetsExporting}
        assetIds={[]}
        jobId={parseInt(id)}
        context={
          externalId !== undefined && externalId !== null && externalId.length > 0 ? `${externalId} ${client}` : `${client}`
        }
      />
      <ConfirmStatusChange
        name={name}
        isOpen={isStatusChangeModalOpen}
        setOpen={setIsStatusChangeModalOpen}
        changeStatus={changeStatus}
      />
      <DocumentSlide
        entity={EntityType.Job}
        entityId={parseInt(id)}
        open={isDocumentSlideOpen}
        setOpen={setIsDocumentSlideOpen}
      />
      <ExportFrameworkModal
        isOpen={isOpen}
        closeModal={setImportFrameworkModalOpen}
        exportType={
          externalId !== undefined && externalId !== null && externalId.length > 0 ? `${externalId} ${client}` : `${client}`
        }
        jobId={parseInt(id)}
      />
      <ImageQualityModal
        isOpen={isExportImageQualityOpen}
        setOpen={setIsExportImageQualityOpen}
        name={name}
        jobId={parseInt(id)}
        exportImages={exportAllImages}
      />
      <DeleteConfirmation
        itemType="Document"
        isOpen={isDeleteOpen}
        setOpen={setIsDeleteOpen}
        deleteAction={deleteDocument}
      />
    </>
  );
};

export default JobView;
